import request from '@/utils/axios.js';
import Vue from 'vue'
import App from '../App.vue'
import { vm } from '../main.js'
import store from '../store'
export function getRequest(url, query) {
  return request({
    url: url,
    method: 'get',
    params: query,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
export function deleteRequest(url, datas) {
  return request({
    url: url,
    method: 'delete',
    data: JSON.stringify(datas),
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export function postRequest(url, datas) {
  return request({
    url: url,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'token': window.sessionStorage.getItem('token'),
    },
    data: JSON.stringify(datas)
  })
}

export function postRequest2(url, query) {
  return request({
    url: url,
    method: 'post',
    params: query,
    headers: {
      'Content-Type': 'multipart/form-data',
      'token': window.sessionStorage.getItem('token'),
    },

  })
}