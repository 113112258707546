export default {
    checkedpower: function (ccode) {
        var ispower = false;
        if (window.localStorage.getItem('power')) {
            var powers = JSON.parse(window.localStorage.getItem('power'));
            // return
            for (var i = 0; i < powers.length; i++) {
                if (powers[i].code == ccode) {
                    ispower = true;
                    break;
                }
            }
        }
        return ispower;

    }

}

