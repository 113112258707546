import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import cookie from 'vue-cookie'
Vue.prototype.$cookie = cookie;
import ElementUI from 'element-ui';

import 'element-ui/lib/theme-chalk/index.css';
import EasyRing from 'easy-ring'
Vue.use(EasyRing)
Vue.use(ElementUI);
import md5 from 'js-md5';
Vue.prototype.$md5 = md5;
import moment from 'moment'
Vue.use(require('vue-moment'));
Vue.prototype.moment = moment
Vue.filter('dateYMDHMSFormat', function (dateStr, pattern = 'YYYY-MM-DD') {
  return moment(dateStr).format(pattern);
});


import power from "@/utils/power.js";
Vue.prototype.power = power;

import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;


import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)
import vcolorpicker from 'vcolorpicker'
Vue.use(vcolorpicker)



router.beforeEach(async (to, from, next) => {
  let token = sessionStorage.getItem('token');
  if (to.path != "/login" && to.path != "/") {
    if (token != null) {
      next();
    } else {
      next({
        path: "/login"
      });
    }
  } else {
    next();
  }
})
export let vm = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')