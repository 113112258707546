import Vue from 'vue'
import Vuex from 'vuex'
import global from './../Global.vue'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    api: global.api,
    image_base: global.imgurl,
    yuming: global.yuming, //域名
    infor: {},
    // 菜单列表
    moduleList: [],
    // 酒店列表
    hotelList: [],
    userInfor: {},
    messageList: [],
    ParamsList: [],
    roomList: [],
    roomTableList: [],
    roomTableTotal: null,
    add: null
  },
  mutations: {
    // SetModules(state, data) {
    //   state.modules = data;

    // }
  },
  actions: {},
  modules: {}
})