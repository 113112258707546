import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter);
const routes = [
  {
    path: '/',
    name: 'root',
    redirect: '/login',

    // path: '/',
    // component: () => import('../views/Login.vue'),
    // // redirect:'/situs'
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/superAdmin',
    name: 'superAdmin',
    component: () => import('../views/superAdmin.vue')
  },
  {
    path: '/creatHotel',
    name: 'creatHotel',
    component: () => import('../views/creatHotel.vue')
  },
  {
    path: '/choseHotel',
    name: 'choseHotel',
    component: () => import('../views/choseHotel.vue')
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    children: [
      {
        path: '/caidan',
        name: 'caidan',
        component: () => import('../views/caidan/index.vue'),
      }, {
        path: '/caidanSon',
        name: 'caidanSon',
        component: () => import('../views/caidanSon/index.vue'),
      },
      {
        path: '/hotel',
        name: 'hotel',
        component: () => import('../views/hotel/index.vue'),
      },
      {
        path: '/switchHotels',
        name: 'switchHotels',
        component: () => import('../views/switchHotels.vue'),
      },
      {
        path: '/user',
        name: 'user',
        component: () => import('../views/user/index.vue'),
      },
      {
        path: '/homePage',
        name: 'homePage',
        component: () => import('../views/homePage/index.vue'),
      },
      {
        path: '/power',
        name: 'Power',
        component: () => import('../views/Power.vue')
      },
      {
        path: '/cstype',
        name: 'cstype',
        component: () => import('../views/cstype/index.vue'),
      },
      {
        path: '/guanliyuan',
        name: 'guanliyuan',
        component: () => import('../views/guanliyuan/index.vue'),
      },
      {
        path: '/sbTypeManage',
        name: 'sbTypeManage',
        component: () => import('../views/sbTypeManage/index.vue'),
      },

      {
        path: '/roomState',
        name: 'roomState',
        component: () => import('../views/roomState/index.vue'),
      },
      {
        path: '/manage',
        name: 'manage',
        component: () => import('../views/manage/index.vue'),
      },

      {
        path: '/regHotel',
        name: 'regHotel',
        component: () => import('../views/regHotel/index.vue'),
      },
      {
        path: '/sbTypeManageConfig',
        name: 'sbTypeManageConfig',
        component: () => import('../views/sbTypeManageConfig/index.vue'),
      },
      {
        path: '/seasonalConfig',
        name: 'seasonalConfig',
        component: () => import('../views/seasonalConfig/index.vue'),
      },
      {
        path: '/buildingConfig',
        name: 'buildingConfig',
        component: () => import('../views/buildingConfig/index.vue'),
      },
      {
        path: '/buildingConfig',
        name: 'buildingConfig',
        component: () => import('../views/buildingConfig/index.vue'),
      },
      {
        path: '/floorManage',
        name: 'floorManage',
        component: () => import('../views/floorManage/index.vue'),
      }, {
        path: '/roomArea',
        name: 'roomArea',
        component: () => import('../views/roomArea/index.vue'),
      }, {
        path: '/roomTypeManage',
        name: 'roomTypeManage',
        component: () => import('../views/roomTypeManage/index.vue'),
      }, {
        path: '/summerCondit',
        name: 'summerCondit',
        component: () => import('../views/summerCondit/index.vue'),
      }, {
        path: '/winterCondit',
        name: 'winterCondit',
        component: () => import('../views/winterCondit/index.vue'),
      },
      {
        path: '/setTemplate',
        name: 'setTemplate',
        component: () => import('../views/setTemplate/index.vue'),
      },
      {
        path: '/deviceAliases',
        name: 'deviceAliases',
        component: () => import('../views/deviceAliases/index.vue'),
      },
      {
        path: '/contrl',
        name: 'contrl',
        component: () => import('../views/contrl/index.vue'),
      },
      {
        path: '/contrlRoom',
        name: 'contrlRoom',
        component: () => import('../views/contrlRoom/index.vue'),
      },
      {
        path: '/room',
        name: 'room',
        component: () => import('../views/room/index.vue'),
      },
      {
        path: '/roomManage',
        name: 'roomManage',
        component: () => import('../views/roomManage/index.vue'),
      },
      {
        path: '/roomControl',
        name: 'roomControl',
        component: () => import('../views/roomControl/index.vue'),
      },
      {
        path: '/versions',
        name: 'versions',
        component: () => import('../views/versions/index.vue'),
      },
      {
        path: '/powerCard',
        name: 'powerCard',
        component: () => import('../views/powerCard/index.vue'),
      }, {
        path: '/powerCardMange',
        name: 'powerCardMange',
        component: () => import('../views/powerCardMange/index.vue'),
      }, {
        path: '/controlModel',
        name: 'controlModel',
        component: () => import('../views/controlModel/index.vue'),
      },
      {
        path: '/roomBasicSetting',
        name: 'roomBasicSetting',
        component: () => import('../views/roomBasicSetting/index.vue'),
      },
      {
        path: '/roomStatelinkage',
        name: 'roomStatelinkage',
        component: () => import('../views/roomStatelinkage/index.vue'),
      },
      {
        path: '/scheduledTask',
        name: 'scheduledTask',
        component: () => import('../views/scheduledTask/index.vue'),
      },
      {
        path: '/message',
        name: 'message',
        component: () => import('../views/message/index.vue'),
      },
      {
        path: '/TvChannel',
        name: 'TvChannel',
        component: () => import('../views/TvChannel/index.vue'),
      },
      {
        path: '/TvChannelModel',
        name: 'TvChannelModel',
        component: () => import('../views/TvChannelModel/index.vue'),
      },
    ]
  },
]

const router = new VueRouter({
  base: '',
  //mode: 'history',
  mode: 'hash',
  routes,

})
export default router