<script>
//const api = "http://test.bwipms.com:8100/rcu";
//const upload = "http://test.bwipms.com:8100/rcu/uploadDeviceFile";
//const webSocket = "ws://test.bwipms.com:58090/webSocket";

 const api = "https://xiaodu.stsmartiot.com/rcu";
 const upload = "https://xiaodu.stsmartiot.com/rcu/uploadDeviceFile";
 const webSocket = "wss://xiaodu.stsmartiot.com/webSocket";

// const api = "http://192.168.0.128:8100/rcu";
// const upload = "http://192.168.0.128:8100/rcu/uploadDeviceFile";
// const webSocket = "ws://192.168.0.128:58090/webSocket";


// const api = "http://221.214.64.196:8100/rcu";
// const upload = "http://221.214.64.196:8100/rcu/uploadDeviceFile";
// const webSocket = "ws://221.214.64.196:58090/webSocket";
export default {
  api, //接口地址
  upload,
  webSocket,
};
</script> 

<style>
</style>