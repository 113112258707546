<template>
  <div style="height: 100%">
    <router-view />
  </div>
</template>

<script>
import global from "./Global.vue";
import { getRequest, postRequest } from "@/api/api.js";
export default {
  data() {
    return {
      heartCheckNum: null,
      timer: null,
      timer2: null,
      list: [],
      // path: global.webSocket,
    };
  },

  computed: {},
  created() {
    // this.WebSocketTest();
    // this.$store.state.messageList = [];
    // console.log(34, this.$store.state.messageList);
  },
  watch: {
    // cusercode() {
    //   this.WebSocketTest();
    // },
  },
  methods: {
    // WebSocketTest() {
    //   this.num = 0;
    //   this.heartCheckNum = 0;
    //   if ("WebSocket" in window) {
    //     // alert("您的浏览器支持 WebSocket!");
    //     // 打开一个 web socket
    //     this.socket = new WebSocket("ws://test.bwipms.com:58090/webSocket");
    //     this.socket.onopen = this.open;
    //     this.socket.onmessage = this.getMessage;
    //     this.socket.onerror = this.error;
    //   } else {
    //     // 浏览器不支持 WebSocket
    //     alert("您的浏览器不支持 WebSocket!");
    //   }
    // },
    // error() {},
    // getMessage(msg) {
    //   console.log(222, msg);
    //   let hotelInfor = JSON.parse(window.localStorage.getItem("hotelInfor"));
    //   let user = JSON.parse(window.localStorage.getItem("user"));
    //   if (msg.data == "heartcheck" + hotelInfor.hotelId + "-" + user.userCode) {
    //     this.heartCheckNum++;
    //     return;
    //   }
    //   if (msg.data != "服务器连接成功！") {
    //     let obj = JSON.parse(msg.data);
    //     console.log(234, obj);
    //     if (obj.opFlag == "DISPLAY") {
    //       if (
    //         obj.message == "roomStatus" ||
    //         obj.message == "SOSService" ||
    //         obj.message == "otherService"
    //       ) {
    //         var message = {};
    //         message.roomNo = obj.data.roomNo;
    //         message.hotelName = obj.data.hotelName;
    //         message.content = obj.data.content;
    //         message.messageId = obj.data.messageId;
    //         this.addMessage(message);
    //       }
    //     }
    //     if (obj.message == "SOSService" && obj.opFlag == "CLOSE") {
    //       this.deleteIt(obj);
    //       console.log(333);
    //     }
    //   }
    // },
    // addMessage(message) {
    //   this.list.push(message);
    //   for (let i = 0; i < this.list.length; i++) {
    //     for (let j = i + 1; j < this.list.length; j++) {
    //       if (this.list[i].messageId == this.list[j].messageId) {
    //         this.list.splice(j, 1);
    //         j--;
    //       }
    //     }
    //   }
    //   this.$store.state.messageList = this.list;
    //   console.log("列表1", this.$store.state.messageList);
    // },
    // deleteIt(obj) {
    //   let list = this.$store.state.messageList;
    //   list.forEach((item) => {
    //     if (item.messageId == obj.data.messageId) {
    //       console.log(444, obj.data.messageId, item);
    //       list.splice(item, 1);
    //     }
    //   });
    //   this.$store.state.messageList = list;
    //   console.log("列表2", this.$store.state.messageList);
    // },
    // open() {
    //   this.send();
    //   this.send2();
    // },
    // send() {
    //   let hotelInfor = JSON.parse(window.localStorage.getItem("hotelInfor"));
    //   let user = JSON.parse(window.localStorage.getItem("user"));
    //   this.socket.send(
    //     JSON.stringify({
    //       uid: hotelInfor.hotelId + "-" + user.userCode,
    //     })
    //   );
    // },
    // send2() {
    //   let that = this;
    //   let hotelInfor = JSON.parse(window.localStorage.getItem("hotelInfor"));
    //   let user = JSON.parse(window.localStorage.getItem("user"));
    //   if (this.timer) {
    //     clearInterval(this.timer);
    //   }
    //   this.timer = setInterval(() => {
    //     that.socket.send(
    //       JSON.stringify({
    //         heartcheck:
    //           "heartcheck" + hotelInfor.hotelId + "-" + user.userCode + "PC",
    //       })
    //     );
    //     that.num++;
    //     that.compare();
    //   }, 30000); //30000
    // },
    // compare() {
    //   let that = this;
    //   if (this.timer2) {
    //     clearTimeout(this.timer2);
    //   }
    //   this.timer2 = setTimeout(() => {
    //     if (that.num != that.heartCheckNum) {
    //       that.WebSocketTest();
    //     }
    //   }, 5000);
    // },
  },
};
</script>

<style>
html,
body,
#app,
.app-router {
  width: 100%;
  height: 100%;
  -webkit-user-select: none;
}

/* * {
  font-family: system-ui !important;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body,
#app,
.app-router {
  width: 100%;
  height: 100%;
  -webkit-user-select: none;
}

input {
  backdrop-filter: "a";
  background-color: rgba(255, 255, 255, 0);
  background: #fff;
}

input:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill:focus {
  -webkit-text-fill-color: #333 !important;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(8, 18, 161, 0.1);
}

.disinp {
  color: #000 !important;
  border: 0 !important;
  background-color: rgba(255, 255, 255, 0) !important;
} */
</style>
<style>
/* input {
  border: 1px solid #e8ecef !important;
  /* outline: none; */
/* box-shadow: 2px 2px 4px #b1c9f1 inset, -2px -2px 4px #ffffff inset; */
/* height: 28px;
  border-radius: 3px;
  padding-left: 8px;
  color: #6d798e;
  font-size: 14px;
  width: 110px;
}  */
/* >>>input:focus {
  border-color: pink !important;
} */
input:focus {
  border: 1px solid #387dff !important;
}

input::-webkit-input-placeholder {
  color: #c0c4cc;
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #c0c4cc;
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #c0c4cc;
}
input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c0c4cc;
}
.el-input__inner::-webkit-input-placeholder {
  color: #c0c4cc;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  /* width: 32px;
  height: 32px; */
  background: #387dff !important;
}
.el-pagination__jump {
  font-size: 14px !important;
  font-weight: 400;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.65) !important;
}
>>> .el-button {
  background: #38ff59;
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  content: "";
  position: absolute;
  background-color: #fff !important;
  z-index: 1;
}
</style>