import axios from 'axios';
import {vm} from "../main.js";
import global from './../Global.vue'

const request = axios.create();

if (process.env.NODE_ENV === 'development') {
  request.defaults.baseURL = global.api;
} else if (process.env.NODE_ENV === 'production') {
  request.defaults.baseURL = global.api;
}

// 请求拦截 即预处理请求 添加token
request.interceptors.request.use(

  config => {
    if (config.url != '/login' || config.url != '/') {
      config.headers['token'] = window.sessionStorage.getItem('token')
    }
    return config
  },
  err => {
    return Promise.reject(err)
  }
)

request.interceptors.response.use(
  response => {
    if (response.data.status == 9999) {
      vm.$router.replace("/login");
      return
    }
    return response.data
  },
  error => {
    alert("服务器连接错误");
    // this.$message({
    //   message: "服务器连接错误",
    //   center: true,
    // });
  })

export default request